<template>
  <div id="typing-indicator" class="vac-message-wrapper">
    <div class="vac-message-box">
      <div class="vac-message-container">
        <div class="vac-message-card">
          <div style="display: table-row">
            <div
              class="vac-format-message-wrapper"
              style="display: table-cell; margin: 20px"
            >
              {{ translations[lang] }}
            </div>
            <div style="display: table-cell" class="ticontainer">
              <div class="tiblock">
                <div class="tidot"></div>
                <div class="tidot"></div>
                <div class="tidot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypingIndicator',
  props: {
    lang: {
      type: String,
      default: 'EN-GB'
    }
  },
  data () {
    return {
      translations: {
        'EN-GB': 'Masspredict is typing',
        SV: 'Masspredict skriver',
        ZH: 'Masspredict正在打字'
      }
    }
  }
}
</script>

<style>
.tiblock {
  align-items: center;
  display: flex;
  height: 35px;
  margin-right: 0px;
  margin-left: 10px;
}

.ticontainer .tidot {
  background-color: white;
}

.ticontainer {
  margin-right: 5%;
}
.tidot {
  animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 4px;
  margin-right: 2px;
  width: 4px;
}

@keyframes mercuryTypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
  }
  28% {
    -webkit-transform: translateY(-5px);
  }
  44% {
    -webkit-transform: translateY(0px);
  }
}
.tidot:nth-child(1) {
  animation-delay: 200ms;
}
.tidot:nth-child(2) {
  animation-delay: 300ms;
}
.tidot:nth-child(3) {
  animation-delay: 400ms;
}
.vac-message-wrapper .vac-message-card {
  white-space: normal !important;
}
</style>
