<template>
<div id="mp-chat-wrapper">
     <transition name="fade-out">
    <img v-if="showLogo" v-lazy="logoUrl" id="mp-logo" alt="Masspredict logo" />
     </transition>
      <div v-if="message" class="mp-info">
          <div class="mb-6">
            <h2>
              {{ message }}
            </h2>
          </div>
          <button v-if="message && !tryOut" id="create-study-button" @onclick="openSignup()">Create your own Study</button>
        </div>
      <chat-window v-if="!message"
        :height="screenHeight"
        :current-user-id="currentUserId"
        :rooms="rooms"
        :rooms-loaded="true"
        :messages="messages"
        :messages-loaded="true"
        :show-files="false"
        :show-emojis="false"
        :show-audio="false"
        :show-reaction-emojis="false"
        :show-new-messages-divider="false"
        :text-formatting="{disabled: true}"
        :message-actions="[]"
        :room-info-enabled="false"
        :single-room="true"
        :text-messages="{'TYPE_MESSAGE': placeholder}"
        :auto-scroll="{
          send: {
            new: true,
            newAfterScrollUp: true
          },
          receive: {
            new: true,
            newAfterScrollUp: true
          }
        }"
        theme="dark"
        @send-message="sendTextMessage"
      />
  <div style="display: none">
    <SliderInput
      :slider-value="currentSliderValue"
      @submitPointEstimate="sendSliderMessage"
    />
    <Buttons
      :buttons="currentButtons"
      @submitButton="sendButtonMessage"
    />
    <typing-indicator :lang="currentLanguage" />
  <div id="empty-input"></div></div>
</div>
</template>

<script>
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import axios from 'axios'
import { ImmortalDB } from 'immortal-db'
import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import SliderInput from './input/SliderInput.vue'
import Buttons from './input/Buttons.vue'
import TypingIndicator from './TypingIndicator.vue'

Vue.use(Buttons)
Vue.use(SliderInput)
Vue.use(TypingIndicator)
Vue.use(ChatWindow)
Vue.use(VueLazyload)

export default Vue.component('ChatSkin', {
  components: {
    SliderInput,
    Buttons,
    TypingIndicator,
    ChatWindow,
    VueLazyload
  },
  props: {
    session: {
      type: String,
      required: true
    },
    env: {
      type: String,
      required: false,
      default: 'dev'
    },
    channel: {
      type: String,
      requred: true
    },
    h: {
      type: Number,
      required: false,
      default: null
    }
  },
  data () {
    return {
      screenHeight: '0%',
      tryOut: false,
      validTryoutSession: null,
      showLogo: true,
      apiUrl: '',
      logoUrl: 'https://staging-masspredict-chat-widget.s3.eu-north-1.amazonaws.com/Logo_white.png',
      ended: false,
      participant_id: null,
      firstLoad: true,
      session_state: '',
      currentLanguage: 'EN-GB',
      botHeader: { 'content-type': 'application/json' },
      currentButtons: [],
      placeholder: '',
      sessionStates: {
        deleted: 'deleted',
        closed: 'complete',
        draft: 'draft',
        active: 'active'
      },
      displayMessages: {
        message: '',
        ended: 'This study has ended.',
        deleted: 'This study no longer exists.',
        participated: 'You have already participated in this study.',
        error:
          'There seems to be an issue with this study, please try again later.'
      },
      currentInputBoxKey: 'text',
      currentSliderValue: 50,
      currentUserId: 1337,
      message: '',
      rooms: [
        {
          roomId: 1,
          roomName: 'masspredict',
          users: [{ _id: 'masspredict' }, { _id: this.currentUserId }]
        }
      ],
      messages: [],
      currentInputType: 'start'
    }
  },
  computed: {
    textInputBox () {
      return document.getElementsByClassName('vac-box-footer')[0]
    },
    sliderInputBox () {
      return document.getElementById('slider-input-box')
    },
    buttonsInputBox () {
      return document.getElementById('buttons-input-box')
    },
    emptyInputBox () {
      return document.getElementById('empty-input')
    },
    typingIndicator () {
      return document.getElementById('typing-indicator')
    },
    currentInputBox () {
      switch (this.currentInputBoxKey) {
        case 'empty': return this.emptyInputBox
        case 'text': return this.textInputBox
        case 'buttons': return this.buttonsInputBox
        case 'slider': return this.sliderInputBox
        default: return this.textInputBox
      }
    }
  },
  async created () {
    this.setPropData()
    await this.startSession()
    switch (this.session_state) {
      case this.sessionStates.closed:
        this.message = this.displayMessages.ended
        this.stopLoading()
        break
      case this.sessionStates.deleted:
        this.message = this.displayMessages.deleted
        this.stopLoading()
        break
      default:
        this.firstLoad = false
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.h) {
        const vacColMessages = document.getElementsByClassName('vac-col-messages')
        vacColMessages[0].setAttribute('style', `height:${this.h}px`)
      } else {
      }
    })
  },
  methods: {
    async revealChat () {
      await new Promise((resolve) => setTimeout(() => resolve(), 1000)).then(this.showLogo = false)
      await new Promise((resolve) => setTimeout(() => resolve(), 1000)).then(this.screenHeight = '100%')
    },
    setPropData () {
      if (this.channel === 'tryout') {
        this.tryOut = true
      }
      if (this.env === 'dev') {
        this.apiUrl = 'http://localhost'
      } else {
        this.apiUrl = `https://${this.env.substring(0, 4)}.masspredict.io`
      }
    },
    handleSessionCompleted (redirectUrl) {
      setTimeout(() => {
        window.open(redirectUrl)
      }, 2000)
    },
    handleSignup () {
      window.open(`${this.apiUrl}/signup)`)
    },
    stopLoading () {
      this.loading = false
    },
    async startSession () {
      this.participant_id = await ImmortalDB.get(this.session)
      if (this.participant_id) {
        this.message = this.displayMessages.participated
        this.stopLoading()
      } else {
        await axios.get(`${this.apiUrl}/api/v1/bot/${this.session}`)
          .then(
            async function (response) {
              this.session_state = response.data.state
              this.currentLanguage = response.data.lang
              if (response.data.participant_id && response.data.bot_resp) {
                this.participant_id = response.data.participant_id
                if (this.session_state !== this.sessionStates.draft) {
                  await ImmortalDB.set(this.session, this.participant_id)
                }
                this.addBotMessage(response.data.bot_resp)
                this.hideTypingIndicator()
                this.revealChat()
              }
            }.bind(this)
          )
          .catch(() => {
            this.message = this.displayMessages.error
            this.stopLoading()
          })
      }
    },
    async dispatchToBot (message) {
      const msgToBot = JSON.stringify({
        message: message,
        type: this.currentInputType
      })
      await axios
        .post(`${this.apiUrl}/api/v1/bot/${this.participant_id}`, msgToBot, this.botHeader)
        .catch((error) => {
          throw new Error(error)
        })
        .then((botResponse) => {
          this.currentInputType =
            botResponse.data[botResponse.data.length - 1].response_type
          this.addBotMessage(botResponse.data)
          this.hideTypingIndicator()
        }).catch(() => {
          this.message = this.displayMessages.error
          this.stopLoading()
        })
    },
    hideInput () {
      this.currentInputBox.replaceWith(this.emptyInputBox)
      this.currentInputBoxKey = 'empty'
    },
    sendTextMessage (message) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: message.content,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString()
        }
      ]
      this.dispatchToBot(message.content)
      this.hideInput()
      this.showTypingIndicator()
    },
    sliderContent (value) {
      if (this.currentLanguage === 'ZH') {
        return `我认为有 ${value}% 的可能性。`
      } else if (this.currentLanguage === 'SV') {
        return `Jag tror det är ${value}% troligt.`
      } else {
        return `I think it's ${value}% likely.`
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    sendSliderMessage (value) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: this.sliderContent(value),
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString()
        }
      ]
      this.dispatchToBot(value)
      this.hideInput()
      this.showTypingIndicator()
    },
    sendButtonMessage (button) {
      this.messages = [
        ...this.messages,
        {
          _id: this.messages.length,
          content: button.title,
          senderId: this.currentUserId,
          timestamp: new Date().toString().substring(16, 21),
          date: new Date().toDateString()
        }
      ]
      this.dispatchToBot(button.payload)
      this.hideInput()
      this.showTypingIndicator()
    },
    showTypingIndicator () {
      const elements = document.getElementsByClassName('vac-messages-container')[0]
      elements.appendChild(this.typingIndicator)
    },
    async hideTypingIndicator () {
      const messageContainer = document.getElementsByClassName('vac-messages-container')[0]
      if (typeof (messageContainer) !== 'undefined' && messageContainer.lastChild.id === 'typing-indicator') {
        await this.sleep(1000)
        messageContainer.removeChild(messageContainer.lastChild)
      }
    },
    async addBotMessage (botResponses) {
      if (this.loading) {
        await new Promise((resolve) => setTimeout(() => resolve(), 2000))
          .then(async () => {
            await new Promise((resolve) => setTimeout(() => resolve())).then(() => {
              this.hideInput()
            })
          })
      }
      await new Promise((resolve) => setTimeout(() => resolve(), 1000)).then(() => {
        for (let i = 0; i < botResponses.length; i++) {
          const response = botResponses[i]
          if (response.text) {
            this.messages = [
              ...this.messages,
              {
                _id: this.messages.length,
                content: response.text,
                senderId: 'masspredict',
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toDateString()
              }
            ]
          }
          if (response.custom && response.custom.ended) {
            this.hideInput()
            if (response.custom.redirect_url) {
              this.handleSessionCompleted(response.custom.redirect_url)
            }
          } else if (response.custom && response.custom.user_instruction) {
            this.placeholder = response.custom.user_instruction
            this.currentInputBox.replaceWith(this.textInputBox)
            this.currentInputBoxKey = 'text'
          } else if (response.buttons) {
            this.currentButtons = response.buttons
            this.currentInputBox.replaceWith(this.buttonsInputBox)
            this.currentInputBoxKey = 'buttons'
          } else if (response.custom &&
            (response.custom.slider || response.custom.slider === 0)) {
            this.currentSliderValue = response.custom.slider
            this.currentInputBox.replaceWith(this.sliderInputBox)
            this.currentInputBoxKey = 'slider'
          } else if (response.image && response.image.url) {
            this.messages = [
              ...this.messages,
              {
                _id: this.messages.length,
                senderId: 'masspredict',
                timestamp: new Date().toString().substring(16, 21),
                date: new Date().toDateString(),
                content: response.image.caption || '',
                files: [
                  {
                    type: 'png',
                    url: response.image.url
                  }
                ]
              }
            ]
          } else {
            this.currentInputBox.replaceWith(this.textInputBox)
            this.currentInputBoxKey = 'text'
          }
        }
      })
    }
  }
})
</script>

<style>
#mp-logo {
  min-width: 200px;
  width: 30%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
#chatText {
  height: 600px;
  overflow: auto;
}
#mp-chat-wrapper {
  height: 600px;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (max-width: 959px) {
  #inputText {
    width: 600px;
  }
}
.elevation-12 {
  margin-top: 30px;
}

#toolbar-line {
  height: 20px;
  background-color: #131415;
}

.mp-info {
  color: #F8F9FA;
  text-align: center;
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 0;
  margin-right: 3%;
  margin-left: 3%;
  margin-top: 30px;
  min-width: 200px;
  font-family: 'RMNeue-Light', Helvetica, sans-serif !important;;
}

.fade-out-enter-active, .fade-out-leave-active{
  transition: opacity 0.5s;
}
.fade-out-enter, .fade-out-leave-to {
  opacity: 0;
}

#create-study-button {
  border-color: #F8F9FA;
  border: 1px solid #F8F9FA;
  font-size: 13px;
  color: #F8F9FA;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: transparent;
  font-family: 'RMNeue-Regular', Helvetica, sans-serif !important;;
  text-transform: uppercase;
  width: 40%;
  min-width: 200px;
}

#create-study-button:hover {
  background-color: #4e4e4e
}

.vac-media-preview {
  position: fixed;
}

.vac-message-wrapper .vac-message-box {
  max-width: 80%;
}
</style>
