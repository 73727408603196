var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"mp-chat-wrapper"}},[_c('transition',{attrs:{"name":"fade-out"}},[(_vm.showLogo)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.logoUrl),expression:"logoUrl"}],attrs:{"id":"mp-logo","alt":"Masspredict logo"}}):_vm._e()]),(_vm.message)?_c('div',{staticClass:"mp-info"},[_c('div',{staticClass:"mb-6"},[_c('h2',[_vm._v("\n              "+_vm._s(_vm.message)+"\n            ")])]),(_vm.message && !_vm.tryOut)?_c('button',{attrs:{"id":"create-study-button"},on:{"onclick":function($event){return _vm.openSignup()}}},[_vm._v("Create your own Study")]):_vm._e()]):_vm._e(),(!_vm.message)?_c('chat-window',{attrs:{"height":_vm.screenHeight,"current-user-id":_vm.currentUserId,"rooms":_vm.rooms,"rooms-loaded":true,"messages":_vm.messages,"messages-loaded":true,"show-files":false,"show-emojis":false,"show-audio":false,"show-reaction-emojis":false,"show-new-messages-divider":false,"text-formatting":{disabled: true},"message-actions":[],"room-info-enabled":false,"single-room":true,"text-messages":{'TYPE_MESSAGE': _vm.placeholder},"auto-scroll":{
          send: {
            new: true,
            newAfterScrollUp: true
          },
          receive: {
            new: true,
            newAfterScrollUp: true
          }
        },"theme":"dark"},on:{"send-message":_vm.sendTextMessage}}):_vm._e(),_c('div',{staticStyle:{"display":"none"}},[_c('SliderInput',{attrs:{"slider-value":_vm.currentSliderValue},on:{"submitPointEstimate":_vm.sendSliderMessage}}),_c('Buttons',{attrs:{"buttons":_vm.currentButtons},on:{"submitButton":_vm.sendButtonMessage}}),_c('typing-indicator',{attrs:{"lang":_vm.currentLanguage}}),_c('div',{attrs:{"id":"empty-input"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }