<template>
  <div id="masspredict-chat-widget">
    <link rel="preload" as="font" href="https://staging-masspredict-chat-widget.s3.eu-north-1.amazonaws.com/fonts/RMNeue-Regular.woff" type="font/woff" crossorigin="anonymous">
    <link rel="preload" as="font" href="https://staging-masspredict-chat-widget.s3.eu-north-1.amazonaws.com/fonts/RMNeue-Light.woff" type="font/woff" crossorigin="anonymous">
    <chat-skin :session="session" :env="env" :channel="channel" :h="h"></chat-skin>
  </div>
</template>

<script>
import ChatSkin from './components/ChatWindow.vue'

export default {
  components: { ChatSkin },
  props: {
    session: String,
    env: String,
    channel: String,
    h: Number
  }
}
</script>

<style scoped>
#masspredict-chat-widget{
  /* List of available fonts: , RMNeue-LightItalic, RMNeue-Light, RMNeue-Bold, RMNeue-BoldItalic,
  RMNeue-Italic */
  font-family: 'RMNeue-Light', Helvetica, sans-serif !important;
  overscroll-behavior-y: none;
  background-color: #131415;
  position: relative;
  height: 100%;
  min-width: 300px;
  min-height: 500px
}
html {
  margin: 0;
  overscroll-behavior: none;
  background-color: #131415;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgb(255, 255, 255) !important;
}
.logo {
  margin-bottom: 20%;
}

</style>
